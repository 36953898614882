define("discourse/plugins/discourse-graphviz/initializers/discourse-graphviz", ["exports", "jquery", "pretty-text/sanitizer", "discourse/lib/debounce", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _jquery, _sanitizer, _debounce, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-graphviz",
    renderGraphs($containers) {
      $containers.each((_, container) => {
        const $container = (0, _jquery.default)(container);

        // if the container content has not yet been replaced
        // do nothing
        if (!$container.find("svg").length) {
          this.renderGraph($container);
        }
      });
    },
    renderGraph($container) {
      const graphDefinition = $container.text().trim();
      const engine = $container.attr("data-engine");
      const $spinner = (0, _jquery.default)("<div class='spinner tiny'></div>");
      $container.html($spinner);
      (0, _loadScript.default)("/plugins/discourse-graphviz/javascripts/viz-3.0.1.js").then(() => {
        $container.removeClass("is-loading");
        try {
          /* global vizRenderStringSync */
          const svgChart = vizRenderStringSync(graphDefinition, {
            format: "svg",
            engine
          });
          $container.html(svgChart);
        } catch (e) {
          const $error = (0, _jquery.default)(`<div class='graph-error'>${(0, _sanitizer.escape)(e.message)}</div>`);
          $container.html($error);
        }
      });
    },
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_graphviz_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.22", api => {
          api.decorateCooked($elem => {
            const $graphviz = $elem.find(".graphviz");
            if ($graphviz.length) {
              (0, _debounce.default)(this, this.renderGraphs, $graphviz, 200);
            }
          }, {
            id: "graphviz"
          });
        });
      }
    }
  };
});